import React, { useEffect, useState } from "react";
import { languagesData } from "../data/languagesData";
import { FaCheck } from "react-icons/fa";
import Languages from "../languages.json";
import $ from "jquery";
import { ThreeDots } from "react-loader-spinner";

const Home = ({ languageObj, setLanguageObj }) => {
  const [isSpinner, setIsSpinner] = useState(true);

  const handleLanguageChange = (newLanguage) => {
    setLanguageObj(newLanguage);
    localStorage.setItem("EriaWebsiteLanguage", JSON.stringify(newLanguage));
  };

  let headerProjectTitleSectoral =
    Languages[languageObj.languageCode].Header.headerProjectTitleSectoral;
  let headerProjectTitleSpecial =
    Languages[languageObj.languageCode].Header.headerProjectTitleSpecial;
  let headerEczaTrendSubtitle =
    Languages[languageObj.languageCode].Header.headerEczaTrendSubtitle;
  let headerBilkomSubtitle =
    Languages[languageObj.languageCode].Header.headerBilkomSubtitle;
  let headerTokenSubtitle =
    Languages[languageObj.languageCode].Header.headerTokenSubtitle;
  let headerKapadokyaBalloon =
    Languages[languageObj.languageCode].Header.headerKapadokyaBalloon;
  let headerKapadokyaBalloonSubtitle =
    Languages[languageObj.languageCode].Header.headerKapadokyaBalloonSubtitle;
  let headerCafestalkSubtitle =
    Languages[languageObj.languageCode].Header.headerCafestalkSubtitle;
  let headerEKVKKSubtitle =
    Languages[languageObj.languageCode].Header.headerEKVKKSubtitle;
  let headerHBYSSubtitle =
    Languages[languageObj.languageCode].Header.headerHBYSSubtitle;
  let headerEKOBSSubtitle =
    Languages[languageObj.languageCode].Header.headerEKOBSSubtitle;
  let headerSayacOkuma =
    Languages[languageObj.languageCode].Header.headerSayacOkuma;
  let headerSayacOkumaSubtitle =
    Languages[languageObj.languageCode].Header.headerSayacOkumaSubtitle;
  let headerTekstilBorsası =
    Languages[languageObj.languageCode].Header.headerTekstilBorsası;
  let headerTekstilBorsasıSubtitle =
    Languages[languageObj.languageCode].Header.headerTekstilBorsasıSubtitle;
  let headerDetailsButton =
    Languages[languageObj.languageCode].Header.headerDetailsButton;
  let headerGoToProject =
    Languages[languageObj.languageCode].Header.headerGoToProject;
  let headerHomePage =
    Languages[languageObj.languageCode].Header.headerHomePage;
  let headerAboutUs = Languages[languageObj.languageCode].Header.headerAboutUs;
  let headerProducts =
    Languages[languageObj.languageCode].Header.headerProducts;
  let headerContact = Languages[languageObj.languageCode].Header.headerContact;

  let homeTitle1 = Languages[languageObj.languageCode].Home.homeTitle1;
  let homeExplanation1 =
    Languages[languageObj.languageCode].Home.homeExplanation1;
  let homeTitle2 = Languages[languageObj.languageCode].Home.homeTitle2;
  let homeExplanation2 =
    Languages[languageObj.languageCode].Home.homeExplanation2;
  let homeTitle3 = Languages[languageObj.languageCode].Home.homeTitle3;
  let homeExplanation3 =
    Languages[languageObj.languageCode].Home.homeExplanation3;

  let homeFromTheYear =
    Languages[languageObj.languageCode].Home.homeFromTheYear;
  let homePharmacyMembers =
    Languages[languageObj.languageCode].Home.homePharmacyMembers;
  let homeBalloonMembers =
    Languages[languageObj.languageCode].Home.homeBalloonMembers;
  let homeHospitalMembers =
    Languages[languageObj.languageCode].Home.homeHospitalMembers;
  let homeShippingMembers =
    Languages[languageObj.languageCode].Home.homeShippingMembers;
  let homeSuccessProjects =
    Languages[languageObj.languageCode].Home.homeSuccessProjects;
  let homeHighExperience =
    Languages[languageObj.languageCode].Home.homeHighExperience;

  let homeSectoralTitle =
    Languages[languageObj.languageCode].Home.homeSectoralTitle;
  let homeSectoralExplanation1 =
    Languages[languageObj.languageCode].Home.homeSectoralExplanation1;
  let homeSectoralExplanation2 =
    Languages[languageObj.languageCode].Home.homeSectoralExplanation2;
  let homeEczaTrendInfo =
    Languages[languageObj.languageCode].Home.homeEczaTrendInfo;
  let homeTokenInfo = Languages[languageObj.languageCode].Home.homeTokenInfo;
  let homeLoncaInfo = Languages[languageObj.languageCode].Home.homeLoncaInfo;
  let homeKapadokyaBalloon =
    Languages[languageObj.languageCode].Home.homeKapadokyaBalloon;
  let homeKapadokyaBalloonInfo =
    Languages[languageObj.languageCode].Home.homeKapadokyaBalloonInfo;
  let homeCafestalkInfo =
    Languages[languageObj.languageCode].Home.homeCafestalkInfo;
  let homeEKVKKınfo = Languages[languageObj.languageCode].Home.homeEKVKKınfo;
  let homeTrendDepoInfo =
    Languages[languageObj.languageCode].Home.homeTrendDepoInfo;
  let homeHBYSInfo = Languages[languageObj.languageCode].Home.homeHBYSInfo;
  let homeEKOBSInfo = Languages[languageObj.languageCode].Home.homeEKOBSInfo;
  let homeSayaçOkuma = Languages[languageObj.languageCode].Home.homeSayaçOkuma;
  let homeSayaçOkumaInfo =
    Languages[languageObj.languageCode].Home.homeSayaçOkumaInfo;
  let homeProductsDetail =
    Languages[languageObj.languageCode].Home.homeProductsDetail;
  let homeProductsGoToProject =
    Languages[languageObj.languageCode].Home.homeProductsGoToProject;
  let homeProductsSoon =
    Languages[languageObj.languageCode].Home.homeProductsSoon;

  let homeContactTitle =
    Languages[languageObj.languageCode].Home.homeContactTitle;
  let homeContactSubtitle1 =
    Languages[languageObj.languageCode].Home.homeContactSubtitle1;
  let homeContactSubtitle2 =
    Languages[languageObj.languageCode].Home.homeContactSubtitle2;
  let homeContactCallNow =
    Languages[languageObj.languageCode].Home.homeContactCallNow;
  let homeContactPhoneNumber =
    Languages[languageObj.languageCode].Home.homeContactPhoneNumber;
  let homeContactUs = Languages[languageObj.languageCode].Home.homeContactUs;

  let homePromotionL1Title =
    Languages[languageObj.languageCode].Home.homePromotionL1Title;
  let homePromotionL1Text =
    Languages[languageObj.languageCode].Home.homePromotionL1Text;
  let homePromotionL2Title =
    Languages[languageObj.languageCode].Home.homePromotionL2Title;
  let homePromotionL2Text =
    Languages[languageObj.languageCode].Home.homePromotionL2Text;
  let homePromotionR1Title =
    Languages[languageObj.languageCode].Home.homePromotionR1Title;
  let homePromotionR1Text =
    Languages[languageObj.languageCode].Home.homePromotionR1Text;
  let homePromotionR2Title =
    Languages[languageObj.languageCode].Home.homePromotionR2Title;
  let homePromotionR2Text =
    Languages[languageObj.languageCode].Home.homePromotionR2Text;

  let homePromotionSubTitle =
    Languages[languageObj.languageCode].Home.homePromotionSubTitle;
  let homePromotionSubTitleEria =
    Languages[languageObj.languageCode].Home.homePromotionSubTitleEria;

  let homeBottomFooterSocial =
    Languages[languageObj.languageCode].Home.homeBottomFooterSocial;
  let homeBottomFooterMobile =
    Languages[languageObj.languageCode].Home.homeBottomFooterMobile;
  let homeBottomFooterPartner =
    Languages[languageObj.languageCode].Home.homeBottomFooterPartner;

  const [mobileMenu, setMobileMenu] = useState(false);

  const triggerMobileMenu = () => {
    setMobileMenu(!mobileMenu);
  };

  useEffect(() => {
    /*=============================================
      =       Menu sticky & Scroll to top          =
      =============================================*/
    var windows = $(window);
    var screenSize = windows.width();
    var sticky = $(".header-sticky");
    var $html = $("html");
    var $body = $("body");

    /*===========================================
      =            Submenu viewport position      =
      =============================================*/

    if ($(".has-children--multilevel-submenu").find(".submenu").length) {
      var elm = $(".has-children--multilevel-submenu").find(".submenu");

      elm.each(function () {
        var off = $(this).offset();
        var l = off.left;
        var w = $(this).width();
        var docH = windows.height();
        var docW = windows.width() - 10;
        var isEntirelyVisible = l + w <= docW;

        if (!isEntirelyVisible) {
          $(this).addClass("left");
        }
      });
    }
    /*==========================================
    =            mobile menu active            =
    ============================================*/

    // $("#mobile-menu-trigger").on("click", function () {
    //   $("#mobile-menu-overlay").addClass("active");
    //   $body.addClass("no-overflow");
    // });

    // $("#mobile-menu-close-trigger").on("click", function () {
    //   $("#mobile-menu-overlay").removeClass("active");
    //   $body.removeClass("no-overflow");
    // });

    // $(".offcanvas-navigation--onepage ul li a").on("click", function () {
    //   $("#mobile-menu-overlay").removeClass("active");
    //   $body.removeClass("no-overflow");
    // });

    /*Close When Click Outside*/
    // $body.on("click", function (e) {
    //   var $target = e.target;
    //   if (
    //     !$($target).is(".mobile-menu-overlay__inner") &&
    //     !$($target).parents().is(".mobile-menu-overlay__inner") &&
    //     !$($target).is("#mobile-menu-trigger") &&
    //     !$($target).is("#mobile-menu-trigger i")
    //   ) {
    //     $("#mobile-menu-overlay").removeClass("active");
    //     $body.removeClass("no-overflow");
    //   }
    //   if (
    //     !$($target).is(".search-overlay__inner") &&
    //     !$($target).parents().is(".search-overlay__inner") &&
    //     !$($target).is("#search-overlay-trigger") &&
    //     !$($target).is("#search-overlay-trigger i")
    //   ) {
    //     $("#search-overlay").removeClass("active");
    //     $body.removeClass("no-overflow");
    //   }
    // });

    /*===================================
    =           Menu Activeion          =
    ===================================*/
    var cururl = window.location.pathname;
    var curpage = cururl.substr(cururl.lastIndexOf("/") + 1);
    var hash = window.location.hash.substr(1);
    if ((curpage == "" || curpage == "/" || curpage == "admin") && hash == "") {
      //$("nav .navbar-nav > li:first-child").addClass("active");
    } else {
      $(".navigation-menu li").each(function () {
        $(this).removeClass("active");
      });
      if (hash != "")
        $(".navigation-menu li a[href*='" + hash + "']")
          .parents("li")
          .addClass("active");
      else
        $(".navigation-menu li a[href*='" + curpage + "']")
          .parents("li")
          .addClass("active");
    }

    /*=========================================
    =             open menu Active            =
    ===========================================*/
    $(".openmenu-trigger").on("click", function (e) {
      e.preventDefault();
      $(".open-menuberger-wrapper").addClass("is-visiable");
    });

    $(".page-close").on("click", function (e) {
      e.preventDefault();
      $(".open-menuberger-wrapper").removeClass("is-visiable");
    });

    /*=========================================
    =             open menu Active            =
    ===========================================*/
    $("#open-off-sidebar-trigger").on("click", function () {
      $("#page-oppen-off-sidebar-overlay").addClass("active");
      $body.addClass("no-overflow");
    });

    $("#menu-close-trigger").on("click", function () {
      $("#page-oppen-off-sidebar-overlay").removeClass("active");
      $body.removeClass("no-overflow");
    });

    /*=============================================
    =            search overlay active            =
    =============================================*/

    $("#search-overlay-trigger").on("click", function () {
      $("#search-overlay").addClass("active");
      $body.addClass("no-overflow");
    });

    $("#search-close-trigger").on("click", function () {
      $("#search-overlay").removeClass("active");
      $body.removeClass("no-overflow");
    });

    /*=============================================
    =            hidden icon active            =
    =============================================*/

    $("#hidden-icon-trigger").on("click", function () {
      $("#hidden-icon-wrapper").toggleClass("active");
    });

    /*=============================================
    =            newsletter popup active            =
    =============================================*/

    $("#newsletter-popup-close-trigger").on("click", function () {
      $("#newsletter-popup").removeClass("active");
    });

    /*=========================================
    =             open menu Active            =
    ===========================================*/
    $(".share-icon").on("click", function (e) {
      e.preventDefault();
      $(".entry-post-share").toggleClass("opened");
    });

    $body.on("click", function () {
      $(".entry-post-share").removeClass("opened");
    });
    // Prevent closing dropdown upon clicking inside the dropdown
    $(".entry-post-share").on("click", function (e) {
      e.stopPropagation();
    });

    /*=============================================
    =            offcanvas mobile menu            =
    =============================================*/
    var $offCanvasNav = $(".offcanvas-navigation"),
      $offCanvasNavSubMenu = $offCanvasNav.find(".sub-menu");

    /*Add Toggle Button With Off Canvas Sub Menu*/
    $offCanvasNavSubMenu
      .parent()
      .prepend('<span class="menu-expand"><i></i></span>');

    /*Close Off Canvas Sub Menu*/
    $offCanvasNavSubMenu.slideUp();

    /*Category Sub Menu Toggle*/
    $offCanvasNav.on("click", "li a, li .menu-expand", function (e) {
      var $this = $(this);
      if (
        $this
          .parent()
          .attr("class")
          .match(/\b(menu-item-has-children|has-children|has-sub-menu)\b/) &&
        ($this.attr("href") === "#" || $this.hasClass("menu-expand"))
      ) {
        e.preventDefault();
        if ($this.siblings("ul:visible").length) {
          $this.parent("li").removeClass("active");
          $this.siblings("ul").slideUp();
        } else {
          $this.parent("li").addClass("active");
          $this
            .closest("li")
            .siblings("li")
            .removeClass("active")
            .find("li")
            .removeClass("active");
          $this.closest("li").siblings("li").find("ul:visible").slideUp();
          $this.siblings("ul").slideDown();
        }
      }
    });

    return () => {
      // Event listeners'ı temizlemek için
      $("#mobile-menu-trigger").off("click");
      $("#mobile-menu-close-trigger").off("click");
      $(".offcanvas-navigation--onepage ul li a").off("click");
      $body.off("click");

      $(".openmenu-trigger").off("click");
      $(".page-close").off("click");
      $("#open-off-sidebar-trigger").off("click");
      $("#menu-close-trigger").off("click");
      $("#search-overlay-trigger").off("click");
      $("#search-close-trigger").off("click");
      $("#hidden-icon-trigger").off("click");
      $("#newsletter-popup-close-trigger").off("click");
      $(".share-icon").off("click");
      $(".entry-post-share").off("click");
      $offCanvasNav.off("click", "li a, li .menu-expand");
    };
  }, []);

  useEffect(() => {
    setIsSpinner(true);

    setTimeout(() => {
      setIsSpinner(false);
    }, 1000);
  }, []);

  return (
    <>
      {/* <div className="preloader-activate preloader-active open_tm_preloader">
        <div className="preloader-area-wrap">
          <div className="spinner d-flex justify-content-center align-items-center h-100">
            <div className="bounce1" />
            <div className="bounce2" />
            <div className="bounce3" />
          </div>
        </div>
      </div> */}
      {isSpinner && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh", // veya ihtiyaca göre başka bir yükseklik
          }}
        >
          <ThreeDots
            visible={true}
            height="80"
            width="80"
            color={"rgb(54, 162, 235)"}
            radius="9"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      )}

      {!isSpinner && (
        <>
          {/*====================  header area ====================*/}
          <div className="header-area header-area--absolute">
            <div className="header-top-bar-info border-bottom d-none d-lg-block">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <div className="top-bar-wrap">
                      <div className="top-bar-left"></div>
                      <div className="top-bar-right">
                        <ul className="top-bar-info">
                          <li className="info-item">
                            <a
                              href="tel:01228899900"
                              className="info-link text-white"
                            >
                              <i className="info-icon fa fa-phone" />
                              <span className="info-text">
                                <strong>0352 224 60 68</strong>
                              </span>
                            </a>
                          </li>
                          <li className="info-item text-white">
                            <i className="info-icon fa fa-map-marker-alt" />
                            <span className="info-text">
                              Erciyes Teknopark 1. Bina No:65
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="header-bottom-wrap header-sticky">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="header position-relative">
                      {/* brand logo */}
                      <div className="header__logo">
                        <a href="/">
                          <img
                            src="assets/photos/eriateknoloji.png"
                            className="img-fluid light-logo"
                            alt=""
                          />
                          <img
                            src="assets/photos/eriateknolojiBlack.png"
                            className="img-fluid dark-logo"
                            alt=""
                          />
                        </a>
                      </div>
                      <div className="header-right">
                        {/* navigation menu */}
                        <div className="header__navigation menu-style-four d-none d-xl-block">
                          <nav className="navigation-menu">
                            <ul>
                              <li className="has-children has-children--multilevel-submenu">
                                <a href="#/history">
                                  <span>{headerAboutUs}</span>
                                </a>
                              </li>
                              <li className="has-children has-children--multilevel-submenu">
                                <a href="#/history">
                                  <span>{headerProducts}</span>
                                </a>
                                <ul className="submenu">
                                  <li>
                                    <a
                                      href="https://eczatrend.com/"
                                      target="_blank"
                                    >
                                      <span style={{ color: "gray" }}>
                                        EczaTrend
                                      </span>
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="http://camoballoon.com/"
                                      target="_blank"
                                    >
                                      <span style={{ color: "gray" }}>
                                        {headerKapadokyaBalloon}
                                      </span>
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="http://cafestalk.com/"
                                      target="_blank"
                                    >
                                      <span style={{ color: "gray" }}>
                                        Cafestalk
                                      </span>
                                    </a>
                                  </li>
                                  <li>
                                    <a href="#/history#kvkk">
                                      <span style={{ color: "gray" }}>
                                        E-KVKK
                                      </span>
                                    </a>
                                  </li>
                                  <li>
                                    <a href="#/history#hbys">
                                      <span style={{ color: "gray" }}>
                                        {headerHBYSSubtitle}
                                      </span>
                                    </a>
                                  </li>
                                  <li>
                                    <a href="#/history#ekobs">
                                      <span style={{ color: "gray" }}>
                                        {headerEKOBSSubtitle}
                                      </span>
                                    </a>
                                  </li>
                                  <li>
                                    <a href="#/history#sayac">
                                      <span style={{ color: "gray" }}>
                                        {headerSayacOkuma}
                                      </span>
                                    </a>
                                  </li>
                                  <li>
                                    <a href="#/history#token">
                                      <span style={{ color: "gray" }}>
                                        Toptan TokenFlex
                                      </span>
                                    </a>
                                  </li>
                                  <li>
                                    <a href="#/history#lonca">
                                      <span style={{ color: "gray" }}>
                                        Lonca Market
                                      </span>
                                    </a>
                                  </li>
                                </ul>
                              </li>
                              <li className="has-children has-children--multilevel-submenu">
                                <a href="#/contact" id="contactlink">
                                  <span>{headerContact}</span>
                                </a>
                              </li>
                              <li className="has-children has-children--multilevel-submenu">
                                <a>
                                  <img
                                    style={{ width: 30 }}
                                    src={languageObj.languageImage}
                                    alt={languageObj.languageName}
                                  />
                                  <span style={{ marginLeft: 5 }}>
                                    {languageObj.languageName}
                                  </span>
                                </a>
                                <ul className="submenu">
                                  {languagesData.map((item, index) => (
                                    <li
                                      key={index}
                                      onClick={() => handleLanguageChange(item)}
                                    >
                                      <a>
                                        <img
                                          style={{ width: 30 }}
                                          src={item.languageImage}
                                          alt={item.languageName}
                                        />
                                        <span style={{ marginLeft: 5 }}>
                                          {item.languageName}
                                        </span>
                                        {item.languageTag ===
                                          languageObj.languageTag && (
                                          <FaCheck
                                            color="orange"
                                            style={{ marginLeft: 5 }}
                                          />
                                        )}
                                      </a>
                                    </li>
                                  ))}
                                </ul>
                              </li>
                            </ul>
                          </nav>
                        </div>
                        {/* mobile menu */}
                        <div
                          className="mobile-navigation-icon white-md-icon d-block d-xl-none"
                          id="mobile-menu-trigger"
                          onClick={() => triggerMobileMenu()}
                        >
                          <i />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*====================  End of header area  ====================*/}
          <div id="main-wrapper" style={{ position: "relative" }}>
            <div className="site-wrapper-reveal">
              {/*============ Resolutions Hero Start ============*/}
              <div className="resolutions-hero-slider position-relative">
                <div className="swiper-container hero-slider__container">
                  <div className="swiper-wrapper testimonial-slider__wrapper">
                    <div className="swiper-slide">
                      <div
                        className="service-hero-wrapper resolutions-hero-space resolutions-hero-bg "
                        data-swiper-autoplay={500}
                      >
                        <div className="container">
                          <div className="row">
                            <div className="col-lg-12 col-md-12 ml-auto mr-auto">
                              <div className="service-hero-wrap wow move-up">
                                <div className="service-hero-text text-center">
                                  <h3 className="text-white">
                                    {headerProjectTitleSectoral}
                                  </h3>
                                  <h1 className="font-weight--reguler text-white mb-30">
                                    EczaTrend
                                  </h1>
                                  <p className="text-white">
                                    {headerEczaTrendSubtitle}
                                  </p>
                                  <div className="hero-button-group section-space--mt_50">
                                    <a
                                      href="#/history#eczatrend"
                                      className="ht-btn ht-btn-md"
                                    >
                                      {headerDetailsButton}
                                    </a>
                                    <a
                                      href="https://eczatrend.com/"
                                      target="_blank"
                                      className="ht-btn ht-btn-md btn--white"
                                    >
                                      <span className="btn-icon mr-2">
                                        <i className="fa fa-play" />
                                      </span>
                                      {headerGoToProject}
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div
                        className="service-hero-wrapper resolutions-hero-space resolutions-hero-bg "
                        data-swiper-autoplay={500}
                      >
                        <div className="container">
                          <div className="row">
                            <div className="col-lg-12 col-md-12 ml-auto mr-auto">
                              <div className="service-hero-wrap wow move-up">
                                <div className="service-hero-text text-center">
                                  <h3 className="text-white">
                                    {headerProjectTitleSpecial}
                                  </h3>
                                  <h1 className="font-weight--reguler text-white mb-30">
                                    Lonca Market
                                  </h1>
                                  <p className="text-white">
                                    {headerBilkomSubtitle}
                                  </p>
                                  <div className="hero-button-group section-space--mt_50">
                                    <a
                                      href="#/history#lonca"
                                      className="ht-btn ht-btn-md"
                                    >
                                      {headerDetailsButton}
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div
                        className="service-hero-wrapper resolutions-hero-space resolutions-hero-bg "
                        data-swiper-autoplay={500}
                      >
                        <div className="container">
                          <div className="row">
                            <div className="col-lg-12 col-md-12 ml-auto mr-auto">
                              <div className="service-hero-wrap wow move-up">
                                <div className="service-hero-text text-center">
                                  <h3 className="text-white">
                                    {headerProjectTitleSpecial}
                                  </h3>
                                  <h1 className="font-weight--reguler text-white mb-30">
                                    Toptan TokenFlex
                                  </h1>
                                  <p className="text-white">
                                    {headerTokenSubtitle}
                                  </p>
                                  <div className="hero-button-group section-space--mt_50">
                                    <a
                                      href="#/history#token"
                                      className="ht-btn ht-btn-md"
                                    >
                                      {headerDetailsButton}
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div
                        className="service-hero-wrapper resolutions-hero-space resolutions-hero-bg-2"
                        data-swiper-autoplay={500}
                      >
                        <div className="container">
                          <div className="row">
                            <div className="col-lg-12 col-md-12 ml-auto mr-auto">
                              <div className="service-hero-wrap wow move-up">
                                <div className="service-hero-text text-center">
                                  <h3 className="text-white">
                                    {headerProjectTitleSectoral}
                                  </h3>
                                  <h1 className="font-weight--reguler text-white mb-30">
                                    {headerKapadokyaBalloon}
                                  </h1>
                                  <p className="text-white">
                                    {headerKapadokyaBalloonSubtitle}
                                  </p>
                                  <div className="hero-button-group section-space--mt_50">
                                    <a
                                      href="#/history#balon"
                                      className="ht-btn ht-btn-md"
                                    >
                                      {headerDetailsButton}
                                    </a>
                                    <a
                                      href="http://camoballoon.com/"
                                      target="_blank"
                                      className="ht-btn ht-btn-md btn--white"
                                    >
                                      <span className="btn-icon mr-2">
                                        <i className="fa fa-play" />
                                      </span>
                                      {headerGoToProject}
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div
                        className="service-hero-wrapper resolutions-hero-space resolutions-hero-bg-3"
                        data-swiper-autoplay={2000}
                      >
                        <div className="container">
                          <div className="row">
                            <div className="col-lg-12 col-md-12 ml-auto mr-auto">
                              <div className="service-hero-wrap wow move-up">
                                <div className="service-hero-text text-center">
                                  <h3 className="text-white">
                                    {headerProjectTitleSectoral}
                                  </h3>
                                  <h1 className="font-weight--reguler text-white mb-30">
                                    Cafestalk
                                  </h1>
                                  <p className="text-white">
                                    {headerCafestalkSubtitle}
                                  </p>
                                  <div className="hero-button-group section-space--mt_50">
                                    <a
                                      href="#/history#cafestalk"
                                      className="ht-btn ht-btn-md"
                                    >
                                      {headerDetailsButton}
                                    </a>
                                    <a
                                      href="http://cafestalk.com/"
                                      target="_blank"
                                      className="ht-btn ht-btn-md btn--white"
                                    >
                                      <span className="btn-icon mr-2">
                                        <i className="fa fa-play" />
                                      </span>
                                      {headerGoToProject}
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div
                        className="service-hero-wrapper resolutions-hero-space resolutions-hero-bg-4"
                        data-swiper-autoplay={2000}
                      >
                        <div className="container">
                          <div className="row">
                            <div className="col-lg-12 col-md-12 ml-auto mr-auto">
                              <div className="service-hero-wrap wow move-up">
                                <div className="service-hero-text text-center">
                                  <h3 className="text-white">
                                    {headerProjectTitleSectoral}
                                  </h3>
                                  <h1 className="font-weight--reguler text-white mb-30">
                                    E-KVKK
                                  </h1>
                                  <p className="text-white">
                                    {headerEKVKKSubtitle}
                                  </p>
                                  <div className="hero-button-group section-space--mt_50">
                                    <a
                                      href="#/history#kvkk"
                                      className="ht-btn ht-btn-md"
                                    >
                                      {headerDetailsButton}
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div
                        className="service-hero-wrapper resolutions-hero-space resolutions-hero-bg-5"
                        data-swiper-autoplay={2000}
                      >
                        <div className="container">
                          <div className="row">
                            <div className="col-lg-12 col-md-12 ml-auto mr-auto">
                              <div className="service-hero-wrap wow move-up">
                                <div className="service-hero-text text-center">
                                  <h3 className="text-white">
                                    {headerProjectTitleSpecial}
                                  </h3>
                                  <h1 className="font-weight--reguler text-white mb-30">
                                    HBYS
                                  </h1>
                                  <p className="text-white">
                                    {headerHBYSSubtitle}{" "}
                                  </p>
                                  <div className="hero-button-group section-space--mt_50">
                                    <a
                                      href="#/history#hbys"
                                      className="ht-btn ht-btn-md"
                                    >
                                      {headerDetailsButton}
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div
                        className="service-hero-wrapper resolutions-hero-space resolutions-hero-bg-6"
                        data-swiper-autoplay={2000}
                      >
                        <div className="container">
                          <div className="row">
                            <div className="col-lg-12 col-md-12 ml-auto mr-auto">
                              <div className="service-hero-wrap wow move-up">
                                <div className="service-hero-text text-center">
                                  <h3 className="text-white">
                                    {headerProjectTitleSpecial}
                                  </h3>
                                  <h1 className="font-weight--reguler text-white mb-30">
                                    EKOBS
                                  </h1>
                                  <p className="text-white">
                                    {headerEKOBSSubtitle}{" "}
                                  </p>
                                  <div className="hero-button-group section-space--mt_50">
                                    <a
                                      href="#/history#ekobs"
                                      className="ht-btn ht-btn-md"
                                    >
                                      {headerDetailsButton}
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div
                        className="service-hero-wrapper resolutions-hero-space resolutions-hero-bg-7"
                        data-swiper-autoplay={2000}
                      >
                        <div className="container">
                          <div className="row">
                            <div className="col-lg-12 col-md-12 ml-auto mr-auto">
                              <div className="service-hero-wrap wow move-up">
                                <div className="service-hero-text text-center">
                                  <h3 className="text-white">
                                    {headerProjectTitleSpecial}
                                  </h3>
                                  <h1 className="font-weight--reguler text-white mb-30">
                                    {headerSayacOkuma}
                                  </h1>
                                  <p className="text-white">
                                    {headerSayacOkumaSubtitle}
                                  </p>
                                  <div className="hero-button-group section-space--mt_50">
                                    <a
                                      href="#/history#sayac"
                                      className="ht-btn ht-btn-md"
                                    >
                                      {headerDetailsButton}
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div
                        className="service-hero-wrapper resolutions-hero-space resolutions-hero-bg-8"
                        data-swiper-autoplay={2000}
                      >
                        <div className="container">
                          <div className="row">
                            <div className="col-lg-12 col-md-12 ml-auto mr-auto">
                              <div className="service-hero-wrap wow move-up">
                                <div className="service-hero-text text-center">
                                  <h3 className="text-white">
                                    {headerProjectTitleSpecial}
                                  </h3>
                                  <h1 className="font-weight--reguler text-white mb-30">
                                    {headerTekstilBorsası}
                                  </h1>
                                  <p className="text-white">
                                    {headerTekstilBorsasıSubtitle}
                                  </p>
                                  <div className="hero-button-group section-space--mt_50">
                                    <a
                                      href="#/history#sayac"
                                      className="ht-btn ht-btn-md"
                                    >
                                      {headerDetailsButton}
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*============ Resolutions Hero End ============*/}
              {/*===========  feature-images-wrapper  Start =============*/}
              <div className="feature-images-wrapper bg-gray">
                <div className="container">
                  <div className="row">
                    <div className="col-12">
                      <div className="feature-images__five resolutions-hero-bottom">
                        <div className="row">
                          <div className="col-lg-4 col-md-6 wow move-up">
                            {/* ht-box-icon Start */}
                            <div className="ht-box-images style-05">
                              <div
                                className="image-box-wrap"
                                style={{ minHeight: 450, height: 450 }}
                              >
                                <div className="box-image">
                                  <div className="default-image">
                                    <img
                                      className="img-fulid"
                                      src="assets/images/icons/mitech-home-resolutions-box-image-01-100x98.png"
                                      alt=""
                                    />
                                  </div>
                                  <div className="hover-images">
                                    <img
                                      className="img-fulid"
                                      src="assets/images/icons/mitech-home-resolutions-box-image-01-hover-100x98.png"
                                      alt=""
                                    />
                                  </div>
                                </div>
                                <div className="content">
                                  <h5 className="heading">{homeTitle1}</h5>
                                  <div className="text">{homeExplanation1}</div>
                                  <div className="box-images-arrow">
                                    {/*a href="#">
                                                      <span class="button-text">Discover now</span>
                                                      <i class="far fa-long-arrow-right"></i>
                                                  </a*/}
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* ht-box-icon End */}
                          </div>
                          <div className="col-lg-4 col-md-6 wow move-up">
                            {/* ht-box-icon Start */}
                            <div className="ht-box-images style-05">
                              <div
                                className="image-box-wrap"
                                style={{ minHeight: 450, height: 450 }}
                              >
                                <div className="box-image">
                                  <div className="default-image">
                                    <img
                                      className="img-fulid"
                                      src="assets/images/icons/mitech-home-resolutions-box-image-03-100x98.png"
                                      alt=""
                                    />
                                  </div>
                                  <div className="hover-images">
                                    <img
                                      className="img-fulid"
                                      src="assets/images/icons/mitech-home-resolutions-box-image-03-hover-100x98.png"
                                      alt=""
                                    />
                                  </div>
                                </div>
                                <div className="content">
                                  <h5 className="heading">{homeTitle2}</h5>
                                  <div className="text">{homeExplanation2}</div>
                                  <div className="box-images-arrow">
                                    {/*a href="#">
                                                      <span class="button-text">Discover now</span>
                                                      <i class="far fa-long-arrow-right"></i>
                                                  </a*/}
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* ht-box-icon End */}
                          </div>
                          <div className="col-lg-4 col-md-6 wow move-up">
                            {/* ht-box-icon Start */}
                            <div className="ht-box-images style-05">
                              <div
                                className="image-box-wrap"
                                style={{ minHeight: 450, height: 450 }}
                              >
                                <div className="box-image">
                                  <div className="default-image">
                                    <img
                                      className="img-fulid"
                                      src="assets/images/icons/mitech-home-resolutions-box-image-02-100x98.png"
                                      alt=""
                                    />
                                  </div>
                                  <div className="hover-images">
                                    <img
                                      className="img-fulid"
                                      src="assets/images/icons/mitech-home-resolutions-box-image-02-hover-100x98.png"
                                      alt=""
                                    />
                                  </div>
                                </div>
                                <div className="content">
                                  <h5 className="heading">{homeTitle3}</h5>
                                  {languageObj.languageCode == "en" && (
                                    <>
                                      <br></br>
                                    </>
                                  )}
                                  <div className="text">{homeExplanation3}</div>
                                  <div className="box-images-arrow">
                                    {/*a href="#">
                                                      <span class="button-text">Discover now</span>
                                                      <i class="far fa-long-arrow-right"></i>
                                                  </a*/}
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* ht-box-icon End */}
                          </div>
                        </div>
                      </div>
                      {/*div class="section-under-heading text-center section-space--mt_80">2010 yılından bu yana bir çok başarılı projemiz ile Erciyes Teknopark'ta sizlere hizmet vermekteyiz. <a href="#">Take the challenge!</a></div*/}
                    </div>
                  </div>
                </div>
              </div>
              {/*===========  feature-images-wrapper  End =============*/}
              {/*=========== fun fact Wrapper Start ==========*/}
              <div className="fun-fact-wrapper bg-gray section-space--ptb_120">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-4 offset-lg-1">
                      <div className="modern-number-01 mr-5">
                        <h2 className="heading">
                          <span className="mark-text" style={{ fontSize: 120 }}>
                            2010
                          </span>
                          {homeFromTheYear}
                        </h2>
                        <h4 className="heading">
                          {homeSuccessProjects}{" "}
                          <span className="text-color-primary">
                            {homeHighExperience}
                          </span>
                        </h4>
                      </div>
                    </div>
                    <div className="col-lg-7 col-md-8">
                      <div className="row">
                        <div className="col-md-6 col-sm-6 wow move-up">
                          <div className="fun-fact--three text-center">
                            <div className="fun-fact__count counter">7000</div>
                            <h6 className="fun-fact__text">
                              {homePharmacyMembers}
                            </h6>
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-6 wow move-up">
                          <div className="fun-fact--three text-center">
                            <div className="fun-fact__count counter">200</div>
                            <h6 className="fun-fact__text">
                              {homeBalloonMembers}
                            </h6>
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-6 wow move-up">
                          <div className="fun-fact--three text-center">
                            <div className="fun-fact__count counter">135</div>
                            <h6 className="fun-fact__text">
                              {homeHospitalMembers}
                            </h6>
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-6 wow move-up">
                          <div className="fun-fact--three text-center">
                            <div className="fun-fact__count counter">500</div>
                            <h6 className="fun-fact__text">
                              {homeShippingMembers}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*=========== fun fact Wrapper End ==========*/}
              {/*===========  feature-icon-wrapper  Start =============*/}
              <div
                className="feature-icon-wrapper section-space--ptb_100"
                id="eriaprojects"
              >
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="section-title-wrap text-center section-space--mb_40">
                        <h6 className="section-sub-title mb-20">
                          {homeSectoralTitle}{" "}
                        </h6>
                        <h3 className="heading">
                          {homeSectoralExplanation1} <br />{" "}
                          <span className="text-color-primary">
                            {" "}
                            {homeSectoralExplanation2}
                          </span>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="feature-list__one">
                        <div className="row">
                          <div className="col-lg-3 col-md-6 wow move-up">
                            {/* ht-box-icon Start */}
                            <div className="ht-box-icon style-01 single-svg-icon-box">
                              <div
                                className="icon-box-wrap"
                                style={{ minHeight: 375, height: 375 }}
                              >
                                <div
                                  className="icon"
                                  style={{ width: "70%", height: 50 }}
                                >
                                  <div
                                    className="svg-icon"
                                    id="svg-icon-1"
                                    data-svg-icon="assets/images/svg/linea-basic-heart.svg"
                                  >
                                    <img
                                      src="assets/photos/eczatrend.png"
                                      className="img-fluid light-logo"
                                      alt=""
                                    />
                                  </div>
                                </div>
                                <div className="content">
                                  <h5 className="heading">EczaTrend</h5>
                                  <div className="text">
                                    {homeEczaTrendInfo}
                                  </div>
                                  <div className="feature-btn">
                                    <a
                                      href="https://www.eczatrend.com"
                                      target="_blank"
                                    >
                                      <span className="button-text">
                                        {homeProductsGoToProject}
                                      </span>
                                      <i
                                        style={{ marginLeft: 4 }}
                                        className="far fa-long-arrow-right"
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* ht-box-icon End */}
                          </div>
                          <div
                            className="col-lg-3 col-md-6 wow move-up"
                            id="lonca"
                          >
                            {/* ht-box-icon Start */}
                            <div className="ht-box-icon style-01 single-svg-icon-box">
                              <div
                                className="icon-box-wrap"
                                style={{ minHeight: 375, height: 375 }}
                              >
                                <div
                                  className="icon"
                                  style={{ width: "55%", height: 50 }}
                                >
                                  <div
                                    className="svg-icon"
                                    id="svg-icon-1"
                                    data-svg-icon="assets/images/svg/linea-basic-heart.svg"
                                  >
                                    <img
                                      src="assets/photos/lonca.png"
                                      className="img-fluid light-logo"
                                      alt=""
                                    />{" "}
                                  </div>{" "}
                                </div>
                                <div
                                  className="content"
                                  style={{ marginTop: 50 }}
                                >
                                  <h5 className="heading">Lonca Market</h5>
                                  <div className="text">{homeLoncaInfo}</div>
                                  <div className="feature-btn">
                                    <a href="#/history#lonca">
                                      <span className="button-text">
                                        {homeProductsDetail}
                                      </span>
                                      <i
                                        style={{ marginLeft: 4 }}
                                        className="far fa-long-arrow-right"
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* ht-box-icon End */}
                          </div>
                          <div
                            className="col-lg-3 col-md-6 wow move-up"
                            id="token"
                          >
                            {/* ht-box-icon Start */}
                            <div className="ht-box-icon style-01 single-svg-icon-box">
                              <div
                                className="icon-box-wrap"
                                style={{ minHeight: 375, height: 375 }}
                              >
                                <div
                                  className="icon"
                                  style={{ width: "55%", height: 50 }}
                                >
                                  <div
                                    className="svg-icon"
                                    id="svg-icon-1"
                                    data-svg-icon="assets/images/svg/linea-basic-heart.svg"
                                  >
                                    <img
                                      src="assets/photos/token.png"
                                      className="img-fluid light-logo"
                                      alt=""
                                    />{" "}
                                  </div>{" "}
                                </div>
                                <div
                                  className="content"
                                  style={{ marginTop: 50 }}
                                >
                                  <h5 className="heading">Toptan TokenFlex</h5>
                                  <div className="text">{homeTokenInfo}</div>
                                  <div className="feature-btn">
                                    <a href="#/history#token">
                                      <span className="button-text">
                                        {homeProductsDetail}
                                      </span>
                                      <i
                                        style={{ marginLeft: 4 }}
                                        className="far fa-long-arrow-right"
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* ht-box-icon End */}
                          </div>

                          <div className="col-lg-3 col-md-6 wow move-up">
                            {/* ht-box-icon Start */}
                            <div className="ht-box-icon style-01 single-svg-icon-box">
                              <div
                                className="icon-box-wrap"
                                style={{ minHeight: 375, height: 375 }}
                              >
                                <div className="icon" style={{ height: 50 }}>
                                  {" "}
                                  <div
                                    className="svg-icon"
                                    id="svg-icon-1"
                                    data-svg-icon="assets/images/svg/linea-basic-heart.svg"
                                  >
                                    <img
                                      src="assets/photos/balon.png"
                                      className="img-fluid light-logo"
                                      alt=""
                                    />{" "}
                                  </div>
                                </div>
                                <div className="content">
                                  <h5 className="heading">
                                    {homeKapadokyaBalloon}
                                  </h5>
                                  <div className="text">
                                    {homeKapadokyaBalloonInfo}
                                  </div>
                                  <div className="feature-btn">
                                    <a
                                      href="http://camoballoon.com/"
                                      target="_blank"
                                    >
                                      <span className="button-text">
                                        {homeProductsGoToProject}
                                      </span>
                                      <i
                                        style={{ marginLeft: 4 }}
                                        className="far fa-long-arrow-right"
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* ht-box-icon End */}
                          </div>
                          <div className="col-lg-3 col-md-6 wow move-up">
                            {/* ht-box-icon Start */}
                            <div className="ht-box-icon style-01 single-svg-icon-box">
                              <div
                                className="icon-box-wrap"
                                style={{ minHeight: 375, height: 375 }}
                              >
                                <div
                                  className="icon"
                                  style={{ width: "70%", height: 50 }}
                                >
                                  <div
                                    className="svg-icon"
                                    id="svg-icon-1"
                                    data-svg-icon="assets/images/svg/linea-basic-heart.svg"
                                  >
                                    <img
                                      src="assets/photos/cafestalk.png"
                                      className="img-fluid light-logo"
                                      alt=""
                                    />{" "}
                                  </div>
                                </div>
                                <div className="content">
                                  <h5 className="heading">CafeStalk</h5>
                                  <div className="text">
                                    {homeCafestalkInfo}
                                  </div>
                                  <div className="feature-btn">
                                    <a
                                      href="http://cafestalk.com/"
                                      target="_blank"
                                    >
                                      <span className="button-text">
                                        {homeProductsGoToProject}
                                      </span>
                                      <i
                                        style={{ marginLeft: 4 }}
                                        className="far fa-long-arrow-right"
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* ht-box-icon End */}
                          </div>
                          <div
                            className="col-lg-3 col-md-6 wow move-up"
                            id="kvkk"
                          >
                            {/* ht-box-icon Start */}
                            <div className="ht-box-icon style-01 single-svg-icon-box">
                              <div
                                className="icon-box-wrap"
                                style={{ minHeight: 375, height: 375 }}
                              >
                                <div
                                  className="icon"
                                  style={{ width: "70%", height: 50 }}
                                >
                                  <div
                                    className="svg-icon"
                                    id="svg-icon-1"
                                    data-svg-icon="assets/images/svg/linea-basic-heart.svg"
                                  >
                                    <img
                                      src="assets/photos/kvkk.png"
                                      className="img-fluid light-logo"
                                      alt=""
                                    />{" "}
                                  </div>
                                </div>
                                <div className="content">
                                  <h5 className="heading">E-KVKK</h5>
                                  <div className="text">{homeEKVKKınfo}</div>
                                  <div className="feature-btn">
                                    <a href="#/history#kvkk">
                                      <span className="button-text">
                                        {homeProductsSoon}
                                      </span>
                                      <i
                                        style={{ marginLeft: 4 }}
                                        className="far fa-long-arrow-right"
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* ht-box-icon End */}
                          </div>
                          <div className="col-lg-3 col-md-6 wow move-up">
                            {/* ht-box-icon Start */}
                            <div className="ht-box-icon style-01 single-svg-icon-box">
                              <div
                                className="icon-box-wrap"
                                style={{ minHeight: 375, height: 375 }}
                              >
                                <div
                                  className="icon"
                                  style={{ width: "70%", height: 50 }}
                                >
                                  <div
                                    className="svg-icon"
                                    id="svg-icon-1"
                                    data-svg-icon="assets/images/svg/linea-basic-heart.svg"
                                  >
                                    <img
                                      src="assets/photos/trenddepo.png"
                                      className="img-fluid light-logo"
                                      alt=""
                                    />{" "}
                                  </div>
                                </div>
                                <div className="content">
                                  <h5 className="heading">TrendDepo</h5>
                                  <div className="text">
                                    {homeTrendDepoInfo}
                                  </div>
                                  <div className="feature-btn">
                                    <a
                                      href="https://www.eczatrend.com"
                                      target="_blank"
                                    >
                                      <span className="button-text">
                                        {homeProductsGoToProject}
                                      </span>
                                      <i
                                        style={{ marginLeft: 4 }}
                                        className="far fa-long-arrow-right"
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* ht-box-icon End */}
                          </div>
                          <div
                            className="col-lg-3 col-md-6 wow move-up"
                            id="hbys"
                          >
                            {/* ht-box-icon Start */}
                            <div className="ht-box-icon style-01 single-svg-icon-box">
                              <div
                                className="icon-box-wrap"
                                style={{ minHeight: 375, height: 375 }}
                              >
                                <div
                                  className="icon"
                                  style={{ width: "70%", height: 50 }}
                                >
                                  <div
                                    className="svg-icon"
                                    id="svg-icon-1"
                                    data-svg-icon="assets/images/svg/linea-basic-heart.svg"
                                  >
                                    <img
                                      src="assets/photos/eriahis.png"
                                      className="img-fluid light-logo"
                                      alt=""
                                    />{" "}
                                  </div>{" "}
                                </div>
                                <div className="content">
                                  <h5 className="heading">HBYS</h5>
                                  <div className="text">{homeHBYSInfo}</div>
                                  <div className="feature-btn">
                                    <a href="#/history#hbys">
                                      <span className="button-text">
                                        {homeProductsDetail}
                                      </span>
                                      <i
                                        style={{ marginLeft: 4 }}
                                        className="far fa-long-arrow-right"
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* ht-box-icon End */}
                          </div>
                          <div
                            className="col-lg-3 col-md-6 wow move-up"
                            id="bordro"
                          >
                            {/* ht-box-icon Start */}
                            <div className="ht-box-icon style-01 single-svg-icon-box">
                              <div
                                className="icon-box-wrap"
                                style={{ minHeight: 375, height: 375 }}
                              >
                                <div
                                  className="icon"
                                  style={{ width: "70%", height: 50 }}
                                >
                                  <div
                                    className="svg-icon"
                                    id="svg-icon-1"
                                    data-svg-icon="assets/images/svg/linea-basic-heart.svg"
                                  >
                                    <img
                                      src="assets/photos/ekobs.png"
                                      className="img-fluid light-logo"
                                      alt=""
                                    />{" "}
                                  </div>{" "}
                                </div>
                                <div className="content">
                                  <h5 className="heading">EKOBS</h5>
                                  <div className="text">{homeEKOBSInfo}</div>
                                  <div className="feature-btn">
                                    <a href="#/history#ekobs">
                                      <span className="button-text">
                                        {homeProductsDetail}
                                      </span>
                                      <i
                                        style={{ marginLeft: 4 }}
                                        className="far fa-long-arrow-right"
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* ht-box-icon End */}
                          </div>
                          <div
                            className="col-lg-3 col-md-6 wow move-up"
                            id="sayac"
                          >
                            {/* ht-box-icon Start */}
                            <div className="ht-box-icon style-01 single-svg-icon-box">
                              <div
                                className="icon-box-wrap"
                                style={{ minHeight: 375, height: 375 }}
                              >
                                <div className="icon" style={{ height: 50 }}>
                                  <div
                                    className="svg-icon"
                                    id="svg-icon-1"
                                    data-svg-icon="assets/images/svg/linea-basic-heart.svg"
                                  >
                                    <img
                                      src="assets/photos/sayac.png"
                                      className="img-fluid light-logo"
                                      alt=""
                                    />{" "}
                                  </div>{" "}
                                </div>
                                <div className="content">
                                  <h5 className="heading">{homeSayaçOkuma}</h5>
                                  <div className="text">
                                    {homeSayaçOkumaInfo}
                                  </div>
                                  <div className="feature-btn">
                                    <a href="#/history#sayac">
                                      <span className="button-text">
                                        {homeProductsDetail}
                                      </span>
                                      <i
                                        style={{ marginLeft: 4 }}
                                        className="far fa-long-arrow-right"
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* ht-box-icon End */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*===========  feature-icon-wrapper  End =============*/}
              {/*====================  Conact us Section Start ====================*/}
              <div className="contact-us-section-wrappaer processing-contact-us-bg section-space--ptb_120">
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-lg-6 col-lg-6">
                      <div className="conact-us-wrap-one">
                        <h3 className="heading text-white">
                          {homeContactTitle}{" "}
                        </h3>
                        <div className="sub-heading text-white">
                          {homeContactSubtitle1}
                          <br />
                          {homeContactSubtitle2}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-lg-6">
                      <div className="contact-info-two text-center">
                        <div className="icon">
                          <span className="fal fa-phone" />
                        </div>
                        <h6 className="heading font-weight--reguler">
                          {homeContactCallNow}
                        </h6>
                        <h2 className="call-us">
                          <a href="tel:190068668">{homeContactPhoneNumber}</a>
                        </h2>
                        <div className="contact-us-button mt-20">
                          <a href="#/contact" className="btn btn--secondary">
                            {homeContactUs}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*====================  Conact us Section End  ====================*/}
              {/*===========  feature-icon-wrapper  Start =============*/}
              <div className="feature-icon-wrapper section-space--ptb_80 border-bottom">
                <div className="container">
                  <div className="row">
                    <div className="col-12">
                      <div className="feature-list__three">
                        <div className="row">
                          <div className="col-lg-6 wow move-up">
                            <div className="grid-item animate">
                              <div className="ht-box-icon style-03">
                                <div className="icon-box-wrap">
                                  <div className="content-header">
                                    <div className="icon">
                                      <i className="fal fa-life-ring" />
                                    </div>
                                    <h5 className="heading">
                                      {homePromotionL1Title}
                                    </h5>
                                  </div>
                                  <div className="content">
                                    <div className="text">
                                      {homePromotionL1Text}{" "}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 wow move-up">
                            <div className="grid-item animate">
                              <div className="ht-box-icon style-03">
                                <div className="icon-box-wrap">
                                  <div className="content-header">
                                    <div className="icon">
                                      <i className="fal fa-lock-alt" />
                                    </div>
                                    <h5 className="heading">
                                      {homePromotionR1Title}
                                    </h5>
                                  </div>
                                  <div className="content">
                                    <div className="text">
                                      {homePromotionR1Title}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 wow move-up">
                            <div className="grid-item animate">
                              <div className="ht-box-icon style-03">
                                <div className="icon-box-wrap">
                                  <div className="content-header">
                                    <div className="icon">
                                      <i className="fal fa-globe" />
                                    </div>
                                    <h5 className="heading">
                                      {homePromotionL2Title}
                                    </h5>
                                  </div>
                                  <div className="content">
                                    <div className="text">
                                      {homePromotionL2Text}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 wow move-up">
                            <div className="grid-item animate">
                              <div className="ht-box-icon style-03">
                                <div className="icon-box-wrap">
                                  <div className="content-header">
                                    <div className="icon">
                                      <i className="fal fa-medal" />
                                    </div>
                                    <h5 className="heading">
                                      {homePromotionR2Title}
                                    </h5>
                                  </div>
                                  <div className="content">
                                    <div className="text">
                                      {homePromotionR2Text}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*=========== feature-icon-wrapper End =============*/}
              {/*========== Call to Action Area Start ============*/}
              <div className="cta-image-area_one section-space--ptb_80 cta-bg-image_one">
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-xl-8 col-lg-7">
                      <div className="cta-content md-text-center">
                        <h3 className="heading text-white">
                          {homePromotionSubTitle}{" "}
                          <span className="text-color-secondary">
                            {" "}
                            {homePromotionSubTitleEria}{" "}
                          </span>{" "}
                        </h3>
                      </div>
                    </div>
                    {/*div class="col-xl-4 col-lg-5">
                      <div class="cta-button-group--one text-center">
                          <a href="#" class="btn btn--white btn-one"><span class="btn-icon mr-2"><i class="far fa-comment-alt-dots"></i></span> Let's talk</a>
                          <a href="#" class="btn btn--secondary  btn-two"><span class="btn-icon mr-2"><i class="far fa-info-circle"></i></span> Get info</a>
                      </div>
                  </div*/}
                  </div>
                </div>
              </div>
              {/*========== Call to Action Area End ============*/}
              {/*====================  brand logo slider area ====================*/}
             
              {/* className="brand-logo-slider-area section-space--ptb_60" */}
              <div>
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      {/* brand logo slider */}
                      {/* <div className="brand-logo-slider__container-area">
                        <div className="swiper-container brand-logo-slider__container">
                          <div className="swiper-wrapper brand-logo-slider__one">
                            <div className="swiper-slide brand-logo brand-logo--slider">
                              <div className="brand-logo__image">
                                <img
                                  src="assets/photos/java.png"
                                  className="img-fluid"
                                  alt=""
                                />
                              </div>
                              <div className="brand-logo__image-hover">
                                <img
                                  src="assets/photos/java.png"
                                  className="img-fluid"
                                  alt=""
                                />
                              </div>
                            </div>
                            <div className="swiper-slide brand-logo brand-logo--slider">
                              <div className="brand-logo__image">
                                <img
                                  src="assets/photos/js.jpg"
                                  className="img-fluid"
                                  alt=""
                                />
                              </div>
                              <div className="brand-logo__image-hover">
                                <img
                                  src="assets/photos/js.jpg"
                                  className="img-fluid"
                                  alt=""
                                />
                              </div>
                            </div>
                            <div className="swiper-slide brand-logo brand-logo--slider">
                              <div className="brand-logo__image">
                                <img
                                  src="assets/photos/html.png"
                                  className="img-fluid"
                                  alt=""
                                />
                              </div>
                              <div className="brand-logo__image-hover">
                                <img
                                  src="assets/photos/html.png"
                                  className="img-fluid"
                                  alt=""
                                />
                              </div>
                            </div>
                            <div className="swiper-slide brand-logo brand-logo--slider">
                              <div className="brand-logo__image">
                                <img
                                  src="assets/photos/css.png"
                                  className="img-fluid"
                                  alt=""
                                />
                              </div>
                              <div className="brand-logo__image-hover">
                                <img
                                  src="assets/photos/css.png"
                                  className="img-fluid"
                                  alt=""
                                />
                              </div>
                            </div>
                            <div className="swiper-slide brand-logo brand-logo--slider">
                              <div className="brand-logo__image">
                                <img
                                  src="assets/photos/react.jpg"
                                  className="img-fluid"
                                  alt=""
                                />
                              </div>
                              <div className="brand-logo__image-hover">
                                <img
                                  src="assets/photos/react.jpg"
                                  className="img-fluid"
                                  alt=""
                                />
                              </div>
                            </div>
                            <div className="swiper-slide brand-logo brand-logo--slider">
                              <div className="brand-logo__image">
                                <img
                                  src="assets/photos/mysql.png"
                                  className="img-fluid"
                                  alt=""
                                />
                              </div>
                              <div className="brand-logo__image-hover">
                                <img
                                  src="assets/photos/mysql.png"
                                  className="img-fluid"
                                  alt=""
                                />
                              </div>
                            </div>
                            <div className="swiper-slide brand-logo brand-logo--slider">
                              <div className="brand-logo__image">
                                <img
                                  src="assets/photos/oracle.png"
                                  className="img-fluid"
                                  alt=""
                                />
                              </div>
                              <div className="brand-logo__image-hover">
                                <img
                                  src="assets/photos/oracle.png"
                                  className="img-fluid"
                                  alt=""
                                />
                              </div>
                            </div>
                            <div className="swiper-slide brand-logo brand-logo--slider">
                              <div className="brand-logo__image">
                                <img
                                  src="assets/photos/websoceet.png"
                                  className="img-fluid"
                                  alt=""
                                />
                              </div>
                              <div className="brand-logo__image-hover">
                                <img
                                  src="assets/photos/websoceet.png"
                                  className="img-fluid"
                                  alt=""
                                />
                              </div>
                            </div>
                            <div className="swiper-slide brand-logo brand-logo--slider">
                              <div className="brand-logo__image">
                                <img
                                  src="assets/photos/hibernate.png"
                                  className="img-fluid"
                                  alt=""
                                />
                              </div>
                              <div className="brand-logo__image-hover">
                                <img
                                  src="assets/photos/hibernate.png"
                                  className="img-fluid"
                                  alt=""
                                />
                              </div>
                            </div>
                            <div className="swiper-slide brand-logo brand-logo--slider">
                              <div className="brand-logo__image">
                                <img
                                  src="assets/photos/android.png"
                                  className="img-fluid"
                                  alt=""
                                />
                              </div>
                              <div className="brand-logo__image-hover">
                                <img
                                  src="assets/photos/android.png"
                                  className="img-fluid"
                                  alt=""
                                />
                              </div>
                            </div>
                            <div className="swiper-slide brand-logo brand-logo--slider">
                              <div className="brand-logo__image">
                                <img
                                  src="assets/photos/ios.jpg"
                                  className="img-fluid"
                                  alt=""
                                />
                              </div>
                              <div className="brand-logo__image-hover">
                                <img
                                  src="assets/photos/ios.jpg"
                                  className="img-fluid"
                                  alt=""
                                />
                              </div>
                            </div>
                            <div className="swiper-slide brand-logo brand-logo--slider">
                              <div className="brand-logo__image">
                                <img
                                  src="assets/photos/jquery.png"
                                  className="img-fluid"
                                  alt=""
                                />
                              </div>
                              <div className="brand-logo__image-hover">
                                <img
                                  src="assets/photos/jquery.png"
                                  className="img-fluid"
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>

              {/*====================  End of brand logo slider area  ====================*/}
            </div>
            {/*====================  footer area ====================*/}
            <div
              className="footer-area-wrapper reveal-footer bg-gray"
              id="contactus"
              style={{ position: "relative" }}
            >
              <div className="footer-area section-space--ptb_80">
                <div className="container">
                  <div className="row footer-widget-wrapper">
                    <div className="col-lg-4 col-md-6 col-sm-6 footer-widget">
                      <div className="footer-widget__logo mb-30">
                        <img
                          style={{ width: "50%", height: "50%" }}
                          src="assets/photos/eriateknolojiBlack.png"
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                      <ul className="footer-widget__list">
                        <li>
                          Erciyes Teknopark 1. Bina No:65 Melikgazi KAYSERİ
                        </li>
                        <li>
                          <a
                            href="mailto:info@eriateknoloji.com"
                            className="hover-style-link"
                          >
                            info@eriateknoloji.com
                          </a>
                        </li>
                        <li>
                          <a
                            href="tel:0352 224 60 68"
                            className="hover-style-link text-black font-weight--bold"
                          >
                            0352 224 60 68
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-6 footer-widget">
                      <h6 className="footer-widget__title mb-20">
                        {homeBottomFooterSocial}
                      </h6>
                      <ul className="footer-widget__list">
                        <li>
                          <a href="https://www.facebook.com/EczaTrend/">
                            <img
                              src="assets/photos/facebook.png"
                              className="img-fluid"
                              alt=""
                            />
                          </a>
                        </li>
                        <li>
                          <a href="https://www.instagram.com/eczatrend/?hl=tr">
                            <img
                              src="assets/photos/insta.png"
                              className="img-fluid"
                              alt=""
                            />
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 footer-widget">
                      <h6 className="footer-widget__title mb-20">
                        {homeBottomFooterMobile}
                      </h6>
                      <ul className="footer-widget__list">
                        <li>
                          <a href="https://play.google.com/store/apps/developer?id=ERIA+Teknoloji">
                            <img
                              src="assets/images/icons/aeroland-button-google-play.jpg"
                              className="img-fluid"
                              alt=""
                            />
                          </a>
                        </li>
                        <li>
                          <a href="https://apps.apple.com/us/developer/eria-teknoloji-yazilim-danismanlik-arge-ve-ticaret/id1189743730">
                            <img
                              src="assets/images/icons/aeroland-button-app-store.jpg"
                              className="img-fluid"
                              alt=""
                            />
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 footer-widget">
                      <h6 className="footer-widget__title mb-20">
                        {homeBottomFooterPartner}
                      </h6>
                      <ul className="footer-widget__list">
                        <li>
                          <a href="https://www.paratika.com.tr/">
                            <img
                              src="assets/photos/paratika.jpg"
                              className="img-fluid"
                              alt=""
                            />
                          </a>
                        </li>
                        <li>
                          <a href="https://www.ups.com.tr//">
                            <img
                              src="assets/photos/ups.png"
                              className="img-fluid"
                              alt=""
                            />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="footer-copyright-area section-space--pb_30">
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-md-6 text-center text-md-left">
                      <span className="copyright-text">
                        © 2021 Eria Teknoloji.
                      </span>
                    </div>
                    <div className="col-md-6 text-center text-md-right">
                      <ul className="list ht-social-networks solid-rounded-icon">
                        <li className="item">
                          <img
                            src="assets/photos/cart.png"
                            className="img-fluid"
                            alt=""
                          />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*====================  End of footer area  ====================*/}
          </div>
          {/*====================  scroll top ====================*/}
          <a href="#" className="scroll-top" id="scroll-top">
            <i className="arrow-top fal fa-long-arrow-up" />
            <i className="arrow-bottom fal fa-long-arrow-up" />
          </a>
          {/*====================  End of scroll top  ====================*/}
        </>
      )}

      {/*====================  mobile menu overlay ====================*/}
      <div
        className={
          mobileMenu ? "mobile-menu-overlay active" : "mobile-menu-overlay"
        }
        id="mobile-menu-overlay"
      >
        <div className="mobile-menu-overlay__inner">
          <div className="mobile-menu-overlay__header">
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-md-6 col-8">
                  {/* logo */}
                  <div className="logo">
                    <a href="/">
                      <img
                        src="assets/photos/eriateknolojiBlack.png"
                        className="img-fluid"
                        alt=""
                      />
                    </a>
                  </div>
                </div>
                <div className="col-md-6 col-4">
                  {/* mobile menu content */}
                  <div
                    className="mobile-menu-content text-right"
                    onClick={() => triggerMobileMenu()}
                  >
                    <span
                      className="mobile-navigation-close-icon"
                      id="mobile-menu-close-trigger"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mobile-menu-overlay__body">
            <nav className="offcanvas-navigation">
              <ul>
                <li className="has-children">
                  <a href="#/history">{headerAboutUs}</a>
                </li>
                <li className="has-children">
                  <a href="#/history">{headerProducts}</a>
                  <ul className="sub-menu">
                    <li>
                      <a href="https://eczatrend.com/" target="_blank">
                        <span>EczaTrend</span>
                      </a>
                    </li>
                    <li>
                      <a href="http://camoballoon.com/" target="_blank">
                        <span>{headerKapadokyaBalloon}</span>
                      </a>
                    </li>
                    <li>
                      <a href="http://cafestalk.com/" target="_blank">
                        <span>Cafestalk</span>
                      </a>
                    </li>
                    <li>
                      <a href="#/history#kvkk">
                        <span>E-KVKK</span>
                      </a>
                    </li>
                    <li>
                      <a href="#/history#hbys">
                        <span>{headerHBYSSubtitle}</span>
                      </a>
                    </li>
                    <li>
                      <a href="#/history#ekobs">
                        <span>{headerEKOBSSubtitle}</span>
                      </a>
                    </li>
                    <li>
                      <a href="#/history#sayac">
                        <span>{headerSayacOkuma}</span>
                      </a>
                    </li>
                    <li>
                      <a href="#/history#token">
                        <span>Toptan TokenFlex</span>
                      </a>
                    </li>
                    <li>
                      <a href="#/history#lonca">
                        <span>Lonca Market</span>
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="has-children">
                  <a href="#/contact" id="contactlink2">
                    {headerContact}
                  </a>
                </li>
                {languagesData.map((item, index) => (
                  <li key={index} onClick={() => handleLanguageChange(item)}>
                    <a>
                      <img
                        style={{ width: 30 }}
                        src={item.languageImage}
                        alt={item.languageName}
                      />
                      <span style={{ marginLeft: 5 }}>{item.languageName}</span>
                      {item.languageTag === languageObj.languageTag && (
                        <FaCheck
                          color={"rgb(255, 159, 64)"}
                          style={{ marginLeft: 5 }}
                        />
                      )}
                    </a>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </div>
      </div>
      {/*====================  End of mobile menu overlay  ====================*/}
      {/*====================  search overlay ====================*/}
      <div className="search-overlay" id="search-overlay">
        <div className="search-overlay__header">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-md-6 ml-auto col-4">
                {/* search content */}
                <div className="search-content text-right">
                  <span
                    className="mobile-navigation-close-icon"
                    id="search-close-trigger"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="search-overlay__inner">
          <div className="search-overlay__body">
            <div className="search-overlay__form">
              <form action="#">
                <input type="text" placeholder="Search" />
              </form>
            </div>
          </div>
        </div>
      </div>
      {/*====================  End of search overlay  ====================*/}
    </>
  );
};

export default Home;
