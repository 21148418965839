import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Languages from "../languages.json";
import { languagesData } from "../data/languagesData";
import { FaCheck } from "react-icons/fa";
import $ from "jquery";
import { ThreeDots } from "react-loader-spinner";

const Contact = ({ languageObj, setLanguageObj }) => {
  const [isSpinner, setIsSpinner] = useState(true);

  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  const handleLanguageChange = (newLanguage) => {
    setLanguageObj(newLanguage);
    localStorage.setItem("EriaWebsiteLanguage", JSON.stringify(newLanguage));
  };

  useEffect(() => {
    /*=============================================
      =       Menu sticky & Scroll to top          =
      =============================================*/
    var windows = $(window);
    var screenSize = windows.width();
    var sticky = $(".header-sticky");
    var $html = $("html");
    var $body = $("body");

    /*===========================================
      =            Submenu viewport position      =
      =============================================*/

    if ($(".has-children--multilevel-submenu").find(".submenu").length) {
      var elm = $(".has-children--multilevel-submenu").find(".submenu");

      elm.each(function () {
        var off = $(this).offset();
        var l = off.left;
        var w = $(this).width();
        var docH = windows.height();
        var docW = windows.width() - 10;
        var isEntirelyVisible = l + w <= docW;

        if (!isEntirelyVisible) {
          $(this).addClass("left");
        }
      });
    }
    /*==========================================
    =            mobile menu active            =
    ============================================*/

    // $("#mobile-menu-trigger").on("click", function () {
    //   $("#mobile-menu-overlay").addClass("active");
    //   $body.addClass("no-overflow");
    // });

    // $("#mobile-menu-close-trigger").on("click", function () {
    //   $("#mobile-menu-overlay").removeClass("active");
    //   $body.removeClass("no-overflow");
    // });

    // $(".offcanvas-navigation--onepage ul li a").on("click", function () {
    //   $("#mobile-menu-overlay").removeClass("active");
    //   $body.removeClass("no-overflow");
    // });

    /*Close When Click Outside*/
    // $body.on("click", function (e) {
    //   var $target = e.target;
    //   if (
    //     !$($target).is(".mobile-menu-overlay__inner") &&
    //     !$($target).parents().is(".mobile-menu-overlay__inner") &&
    //     !$($target).is("#mobile-menu-trigger") &&
    //     !$($target).is("#mobile-menu-trigger i")
    //   ) {
    //     $("#mobile-menu-overlay").removeClass("active");
    //     $body.removeClass("no-overflow");
    //   }
    //   if (
    //     !$($target).is(".search-overlay__inner") &&
    //     !$($target).parents().is(".search-overlay__inner") &&
    //     !$($target).is("#search-overlay-trigger") &&
    //     !$($target).is("#search-overlay-trigger i")
    //   ) {
    //     $("#search-overlay").removeClass("active");
    //     $body.removeClass("no-overflow");
    //   }
    // });

    /*===================================
    =           Menu Activeion          =
    ===================================*/
    var cururl = window.location.pathname;
    var curpage = cururl.substr(cururl.lastIndexOf("/") + 1);
    var hash = window.location.hash.substr(1);
    if ((curpage == "" || curpage == "/" || curpage == "admin") && hash == "") {
      //$("nav .navbar-nav > li:first-child").addClass("active");
    } else {
      $(".navigation-menu li").each(function () {
        $(this).removeClass("active");
      });
      if (hash != "")
        $(".navigation-menu li a[href*='" + hash + "']")
          .parents("li")
          .addClass("active");
      else
        $(".navigation-menu li a[href*='" + curpage + "']")
          .parents("li")
          .addClass("active");
    }

    /*=========================================
    =             open menu Active            =
    ===========================================*/
    $(".openmenu-trigger").on("click", function (e) {
      e.preventDefault();
      $(".open-menuberger-wrapper").addClass("is-visiable");
    });

    $(".page-close").on("click", function (e) {
      e.preventDefault();
      $(".open-menuberger-wrapper").removeClass("is-visiable");
    });

    /*=========================================
    =             open menu Active            =
    ===========================================*/
    $("#open-off-sidebar-trigger").on("click", function () {
      $("#page-oppen-off-sidebar-overlay").addClass("active");
      $body.addClass("no-overflow");
    });

    $("#menu-close-trigger").on("click", function () {
      $("#page-oppen-off-sidebar-overlay").removeClass("active");
      $body.removeClass("no-overflow");
    });

    /*=============================================
    =            search overlay active            =
    =============================================*/

    $("#search-overlay-trigger").on("click", function () {
      $("#search-overlay").addClass("active");
      $body.addClass("no-overflow");
    });

    $("#search-close-trigger").on("click", function () {
      $("#search-overlay").removeClass("active");
      $body.removeClass("no-overflow");
    });

    /*=============================================
    =            hidden icon active            =
    =============================================*/

    $("#hidden-icon-trigger").on("click", function () {
      $("#hidden-icon-wrapper").toggleClass("active");
    });

    /*=============================================
    =            newsletter popup active            =
    =============================================*/

    $("#newsletter-popup-close-trigger").on("click", function () {
      $("#newsletter-popup").removeClass("active");
    });

    /*=========================================
    =             open menu Active            =
    ===========================================*/
    $(".share-icon").on("click", function (e) {
      e.preventDefault();
      $(".entry-post-share").toggleClass("opened");
    });

    $body.on("click", function () {
      $(".entry-post-share").removeClass("opened");
    });
    // Prevent closing dropdown upon clicking inside the dropdown
    $(".entry-post-share").on("click", function (e) {
      e.stopPropagation();
    });

    /*=============================================
    =            offcanvas mobile menu            =
    =============================================*/
    var $offCanvasNav = $(".offcanvas-navigation"),
      $offCanvasNavSubMenu = $offCanvasNav.find(".sub-menu");

    /*Add Toggle Button With Off Canvas Sub Menu*/
    $offCanvasNavSubMenu
      .parent()
      .prepend('<span class="menu-expand"><i></i></span>');

    /*Close Off Canvas Sub Menu*/
    $offCanvasNavSubMenu.slideUp();

    /*Category Sub Menu Toggle*/
    $offCanvasNav.on("click", "li a, li .menu-expand", function (e) {
      var $this = $(this);
      if (
        $this
          .parent()
          .attr("class")
          .match(/\b(menu-item-has-children|has-children|has-sub-menu)\b/) &&
        ($this.attr("href") === "#" || $this.hasClass("menu-expand"))
      ) {
        e.preventDefault();
        if ($this.siblings("ul:visible").length) {
          $this.parent("li").removeClass("active");
          $this.siblings("ul").slideUp();
        } else {
          $this.parent("li").addClass("active");
          $this
            .closest("li")
            .siblings("li")
            .removeClass("active")
            .find("li")
            .removeClass("active");
          $this.closest("li").siblings("li").find("ul:visible").slideUp();
          $this.siblings("ul").slideDown();
        }
      }
    });

    return () => {
      // Event listeners'ı temizlemek için
      $("#mobile-menu-trigger").off("click");
      $("#mobile-menu-close-trigger").off("click");
      $(".offcanvas-navigation--onepage ul li a").off("click");
      $body.off("click");

      $(".openmenu-trigger").off("click");
      $(".page-close").off("click");
      $("#open-off-sidebar-trigger").off("click");
      $("#menu-close-trigger").off("click");
      $("#search-overlay-trigger").off("click");
      $("#search-close-trigger").off("click");
      $("#hidden-icon-trigger").off("click");
      $("#newsletter-popup-close-trigger").off("click");
      $(".share-icon").off("click");
      $(".entry-post-share").off("click");
      $offCanvasNav.off("click", "li a, li .menu-expand");
    };
  }, []);

  let headerProjectTitleSectoral =
    Languages[languageObj.languageCode].Header.headerProjectTitleSectoral;
  let headerProjectTitleSpecial =
    Languages[languageObj.languageCode].Header.headerProjectTitleSpecial;
  let headerEczaTrendSubtitle =
    Languages[languageObj.languageCode].Header.headerEczaTrendSubtitle;
  let headerKapadokyaBalloon =
    Languages[languageObj.languageCode].Header.headerKapadokyaBalloon;
  let headerKapadokyaBalloonSubtitle =
    Languages[languageObj.languageCode].Header.headerKapadokyaBalloonSubtitle;
  let headerCafestalkSubtitle =
    Languages[languageObj.languageCode].Header.headerCafestalkSubtitle;
  let headerEKVKKSubtitle =
    Languages[languageObj.languageCode].Header.headerEKVKKSubtitle;
  let headerHBYSSubtitle =
    Languages[languageObj.languageCode].Header.headerHBYSSubtitle;
  let headerEKOBSSubtitle =
    Languages[languageObj.languageCode].Header.headerEKOBSSubtitle;
  let headerSayacOkuma =
    Languages[languageObj.languageCode].Header.headerSayacOkuma;
  let headerSayacOkumaSubtitle =
    Languages[languageObj.languageCode].Header.headerSayacOkumaSubtitle;
  let headerTekstilBorsası =
    Languages[languageObj.languageCode].Header.headerTekstilBorsası;
  let headerTekstilBorsasıSubtitle =
    Languages[languageObj.languageCode].Header.headerTekstilBorsasıSubtitle;
  let headerDetailsButton =
    Languages[languageObj.languageCode].Header.headerDetailsButton;
  let headerGoToProject =
    Languages[languageObj.languageCode].Header.headerGoToProject;
  let headerHomePage =
    Languages[languageObj.languageCode].Header.headerHomePage;
  let headerAboutUs = Languages[languageObj.languageCode].Header.headerAboutUs;
  let headerProducts =
    Languages[languageObj.languageCode].Header.headerProducts;
  let headerContact = Languages[languageObj.languageCode].Header.headerContact;

  let contactNameAndSurname =
    Languages[languageObj.languageCode].Contact.contactNameAndSurname;
  let contactEmail = Languages[languageObj.languageCode].Contact.contactEmail;
  let contactSubject =
    Languages[languageObj.languageCode].Contact.contactSubject;
  let contactDetail = Languages[languageObj.languageCode].Contact.contactDetail;
  let contactSendMail =
    Languages[languageObj.languageCode].Contact.contactSendMail;
  let contactForInfo =
    Languages[languageObj.languageCode].Contact.contactForInfo;
  let contactContactUs =
    Languages[languageObj.languageCode].Contact.contactContactUs;
  let contactWeWant = Languages[languageObj.languageCode].Contact.contactWeWant;
  let contactWeReturn =
    Languages[languageObj.languageCode].Contact.contactWeReturn;

  let contactPromotionSubTitle =
    Languages[languageObj.languageCode].Contact.contactPromotionSubTitle;
  let contactPromotionSubTitleEria =
    Languages[languageObj.languageCode].Contact.contactPromotionSubTitleEria;

  let contactOurAddress =
    Languages[languageObj.languageCode].Contact.contactOurAddress;

  let contactBottomFooterSocial =
    Languages[languageObj.languageCode].Contact.contactBottomFooterSocial;
  let contactBottomFooterMobile =
    Languages[languageObj.languageCode].Contact.contactBottomFooterMobile;
  let contactBottomFooterPartner =
    Languages[languageObj.languageCode].Contact.contactBottomFooterPartner;

  const [mobileMenu, setMobileMenu] = useState(false);

  const triggerMobileMenu = () => {
    setMobileMenu(!mobileMenu);
  };

  useEffect(() => {
    setIsSpinner(true);

    setTimeout(() => {
      setIsSpinner(false);
    }, 1000);

    setTimeout(() => {
      if (location.hash) {
        const element = document.getElementById(location.hash.substring(1));
        console.log("element: ", element);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
          console.log(
            "element: ",
            element.scrollIntoView({ behavior: "smooth" })
          );
        }
      }
    }, 1100);
  }, []);

  return (
    <>
      {/* <div className="preloader-activate preloader-active open_tm_preloader">
        <div className="preloader-area-wrap">
          <div className="spinner d-flex justify-content-center align-items-center h-100">
            <div className="bounce1" />
            <div className="bounce2" />
            <div className="bounce3" />
          </div>
        </div>
      </div> */}
      {isSpinner && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh", // veya ihtiyaca göre başka bir yükseklik
          }}
        >
          <ThreeDots
            visible={true}
            height="80"
            width="80"
            color={"rgb(54, 162, 235)"}
            radius="9"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      )}

      {!isSpinner && (
        <>
          {/*====================  header area ====================*/}
          <div className="header-area header-area--default">
            {/* Header Bottom Wrap Start */}
            <div className="header-bottom-wrap header-sticky">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="header default-menu-style position-relative">
                      {/* brand logo */}
                      <div className="header__logo">
                        <a href="/">
                          <img
                            src="assets/photos/eriateknolojiBlack.png"
                            className="img-fluid"
                            alt=""
                          />
                        </a>
                      </div>
                      {/* header midle box  */}
                      <div className="header-midle-box">
                        <div className="header-bottom-wrap d-md-block d-none">
                          <div className="header-bottom-inner">
                            <div className="header-bottom-left-wrap">
                              {/* navigation menu */}
                              <div className="header__navigation d-none d-xl-block">
                                <nav className="navigation-menu primary--menu">
                                  <ul>
                                    <li className="has-children has-children--multilevel-submenu">
                                      <a href="/">
                                        <span>{headerHomePage}</span>
                                      </a>
                                    </li>
                                    <li className="has-children has-children--multilevel-submenu">
                                      <a href="#/history">
                                        <span>{headerAboutUs}</span>
                                      </a>
                                    </li>
                                    <li className="has-children has-children--multilevel-submenu">
                                      <a href="#/history">
                                        <span>{headerProducts}</span>
                                      </a>
                                      <ul className="submenu">
                                        <li>
                                          <a
                                            href="https://eczatrend.com/"
                                            target="_blank"
                                          >
                                            <span style={{ color: "gray" }}>
                                              EczaTrend
                                            </span>
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            href="http://camoballoon.com/"
                                            target="_blank"
                                          >
                                            <span style={{ color: "gray" }}>
                                              {headerKapadokyaBalloon}
                                            </span>
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            href="http://cafestalk.com/"
                                            target="_blank"
                                          >
                                            <span style={{ color: "gray" }}>
                                              Cafestalk
                                            </span>
                                          </a>
                                        </li>
                                        <li>
                                          <a href="#/history#kvkk">
                                            <span style={{ color: "gray" }}>
                                              E-KVKK
                                            </span>
                                          </a>
                                        </li>
                                        <li>
                                          <a href="#/history#hbys">
                                            <span style={{ color: "gray" }}>
                                              {headerHBYSSubtitle}
                                            </span>
                                          </a>
                                        </li>
                                        <li>
                                          <a href="#/history#ekobs">
                                            <span style={{ color: "gray" }}>
                                              {headerEKOBSSubtitle}
                                            </span>
                                          </a>
                                        </li>
                                        <li>
                                          <a href="#/history#sayac">
                                            <span style={{ color: "gray" }}>
                                              {headerSayacOkuma}
                                            </span>
                                          </a>
                                        </li>
                                        <li>
                                          <a href="#/history#token">
                                            <span style={{ color: "gray" }}>
                                              Toptan TokenFlex
                                            </span>
                                          </a>
                                        </li>
                                        <li>
                                          <a href="#/history#lonca">
                                            <span style={{ color: "gray" }}>
                                              Lonca Market
                                            </span>
                                          </a>
                                        </li>
                                      </ul>
                                    </li>
                                    <li className="has-children has-children--multilevel-submenu">
                                      <a>
                                        <img
                                          style={{ width: 30 }}
                                          src={languageObj.languageImage}
                                          alt={languageObj.languageName}
                                        />
                                        <span style={{ marginLeft: 5 }}>
                                          {languageObj.languageName}
                                        </span>
                                      </a>
                                      <ul className="submenu">
                                        {languagesData.map((item, index) => (
                                          <li
                                            key={index}
                                            onClick={() =>
                                              handleLanguageChange(item)
                                            }
                                          >
                                            <a>
                                              <img
                                                style={{ width: 30 }}
                                                src={item.languageImage}
                                                alt={item.languageName}
                                              />
                                              <span style={{ marginLeft: 5 }}>
                                                {item.languageName}
                                              </span>
                                              {item.languageTag ===
                                                languageObj.languageTag && (
                                                <FaCheck
                                                  color="orange"
                                                  style={{ marginLeft: 5 }}
                                                />
                                              )}
                                            </a>
                                          </li>
                                        ))}
                                      </ul>
                                    </li>
                                  </ul>
                                </nav>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* mobile menu */}
                      <div
                        className="mobile-navigation-icon d-block d-xl-none"
                        id="mobile-menu-trigger"
                        onClick={() => triggerMobileMenu()}
                      >
                        <i />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Header Bottom Wrap End */}
          </div>
          {/*====================  End of header area  ====================*/}
          {/* breadcrumb-area start */}
          <div
            className="breadcrumb-area"
            style={{ backgroundImage: "url(assets/photos/contactus.jpg)" }}
          >
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="breadcrumb_box text-center">
                    {/* breadcrumb-list start */}
                    <ul className="breadcrumb-list">
                      <li className="breadcrumb-item">
                        <a href="/">{headerHomePage}</a>
                      </li>
                      <li className="breadcrumb-item active">
                        {headerContact}
                      </li>
                    </ul>
                    {/* breadcrumb-list end */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* breadcrumb-area end */}
          <div id="main-wrapper">
            <div className="site-wrapper-reveal">
              {/*====================  Conact us Section Start ====================*/}
              <div className="contact-us-section-wrappaer section-space--pt_100 section-space--pb_70">
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-lg-6 col-lg-6">
                      <div className="conact-us-wrap-one mb-30">
                        <h3 className="heading">
                          {contactForInfo}{" "}
                          <span className="text-color-primary">
                            {contactContactUs}
                          </span>
                        </h3>
                        <div className="sub-heading">
                          {contactWeWant} <br /> {contactWeReturn}
                        </div>
                        <br /> <h5 className="heading">{contactOurAddress}</h5>
                        <div className="sub-heading">
                          Erciyes Teknopark 1. Bina No:65 Melikgazi / KAYSERİ
                          <br />{" "}
                          <a className="hover-style-link text-color-primary">
                            info@eriateknoloji.com
                          </a>
                          <br />{" "}
                          <a className="hover-style-link text-black font-weight--bold">
                            0352 224 60 68
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-lg-6">
                      <div className="contact-form-wrap">
                        {/* <form id="contact-form" action="http://whizthemes.com/mail-php/jowel/mitech/php/mail.php" method="post"> */}
                        <form
                          id="contact-form"
                          action="assets/php/mailer.php"
                          method="post"
                        >
                          <div className="contact-form">
                            <div className="contact-input">
                              <div className="contact-inner">
                                <input
                                  name="con_name"
                                  type="text"
                                  placeholder={contactNameAndSurname}
                                  id="fname"
                                />
                              </div>
                              <div className="contact-inner">
                                <input
                                  name="con_email"
                                  type="email"
                                  placeholder={contactEmail}
                                  id="femail"
                                />
                              </div>
                            </div>
                            <div className="contact-inner">
                              <input
                                name="con_subject"
                                type="text"
                                placeholder={contactSubject}
                                id="fsubject"
                              />
                            </div>
                            <div className="contact-inner contact-message">
                              <textarea
                                name="con_message"
                                placeholder={contactDetail}
                                id="fdetail"
                                defaultValue={""}
                              />
                            </div>
                            <div className="submit-btn mt-20">
                              <button
                                className="ht-btn ht-btn-md"
                                type="submit"
                                id="sendmail"
                              >
                                {contactSendMail}
                              </button>
                              {/*p class="form-messege"></p*/}
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*====================  Conact us Section End  ====================*/}
              {/*========== Call to Action Area Start ============*/}
              <div className="cta-image-area_one section-space--ptb_80 cta-bg-image_two">
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-xl-8 col-lg-7">
                      <div className="cta-content md-text-center">
                        <h3 className="heading">
                          {contactPromotionSubTitle}{" "}
                          <span className="text-color-primary">
                            {" "}
                            {contactPromotionSubTitleEria}
                          </span>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*========== Call to Action Area End ============*/}
            </div>
            {/*====================  footer area ====================*/}
            <div
              id="contact"
              className="footer-area-wrapper reveal-footer bg-gray"
              style={{ position: "relative" }}
            >
              <div className="footer-area section-space--ptb_80">
                <div className="container">
                  <div className="row footer-widget-wrapper">
                    <div className="col-lg-4 col-md-6 col-sm-6 footer-widget">
                      <div className="footer-widget__logo mb-30">
                        <img
                          style={{ width: "50%", height: "50%" }}
                          src="assets/photos/eriateknolojiBlack.png"
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                      <ul className="footer-widget__list">
                        <li>
                          Erciyes Teknopark 1. Bina No:65 Melikgazi KAYSERİ
                        </li>
                        <li>
                          <a
                            href="mailto:info@eriateknoloji.com"
                            className="hover-style-link"
                          >
                            info@eriateknoloji.com
                          </a>
                        </li>
                        <li>
                          <a
                            href="tel:0352 224 60 68"
                            className="hover-style-link text-black font-weight--bold"
                          >
                            0352 224 60 68
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-6 footer-widget">
                      <h6 className="footer-widget__title mb-20">
                        {contactBottomFooterSocial}
                      </h6>
                      <ul className="footer-widget__list">
                        <li>
                          <a href="https://www.facebook.com/EczaTrend/">
                            <img
                              src="assets/photos/facebook.png"
                              className="img-fluid"
                              alt=""
                            />
                          </a>
                        </li>
                        <li>
                          <a href="https://www.instagram.com/eczatrend/?hl=tr">
                            <img
                              src="assets/photos/insta.png"
                              className="img-fluid"
                              alt=""
                            />
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 footer-widget">
                      <h6 className="footer-widget__title mb-20">
                        {contactBottomFooterMobile}
                      </h6>
                      <ul className="footer-widget__list">
                        <li>
                          <a href="https://play.google.com/store/apps/developer?id=ERIA+Teknoloji">
                            <img
                              src="assets/images/icons/aeroland-button-google-play.jpg"
                              className="img-fluid"
                              alt=""
                            />
                          </a>
                        </li>
                        <li>
                          <a href="https://apps.apple.com/us/developer/eria-teknoloji-yazilim-danismanlik-arge-ve-ticaret/id1189743730">
                            <img
                              src="assets/images/icons/aeroland-button-app-store.jpg"
                              className="img-fluid"
                              alt=""
                            />
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 footer-widget">
                      <h6 className="footer-widget__title mb-20">
                        {contactBottomFooterPartner}
                      </h6>
                      <ul className="footer-widget__list">
                        <li>
                          <a href="https://www.paratika.com.tr/">
                            <img
                              src="assets/photos/paratika.jpg"
                              className="img-fluid"
                              alt=""
                            />
                          </a>
                        </li>
                        <li>
                          <a href="https://www.ups.com.tr//">
                            <img
                              src="assets/photos/ups.png"
                              className="img-fluid"
                              alt=""
                            />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="footer-copyright-area section-space--pb_30">
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-md-6 text-center text-md-left">
                      <span className="copyright-text">
                        © 2021 Eria Teknoloji.
                      </span>
                    </div>
                    <div className="col-md-6 text-center text-md-right">
                      <ul className="list ht-social-networks solid-rounded-icon">
                        <li className="item">
                          <img
                            src="assets/photos/cart.png"
                            className="img-fluid"
                            alt=""
                          />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*====================  End of footer area  ====================*/}
          </div>
          {/*====================  scroll top ====================*/}
          <a href="#" className="scroll-top" id="scroll-top">
            <i className="arrow-top fal fa-long-arrow-up" />
            <i className="arrow-bottom fal fa-long-arrow-up" />
          </a>
          {/*====================  End of scroll top  ====================*/}
        </>
      )}

      {/*====================  mobile menu overlay ====================*/}
      <div
        className={
          mobileMenu ? "mobile-menu-overlay active" : "mobile-menu-overlay"
        }
        id="mobile-menu-overlay"
      >
        <div className="mobile-menu-overlay__inner">
          <div className="mobile-menu-overlay__header">
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-md-6 col-8">
                  {/* logo */}
                  <div className="logo">
                    <a href="/">
                      <img
                        src="assets/photos/eriateknolojiBlack.png"
                        className="img-fluid"
                        alt=""
                      />
                    </a>
                  </div>
                </div>
                <div className="col-md-6 col-4">
                  {/* mobile menu content */}
                  <div
                    className="mobile-menu-content text-right"
                    onClick={() => triggerMobileMenu()}
                  >
                    <span
                      className="mobile-navigation-close-icon"
                      id="mobile-menu-close-trigger"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mobile-menu-overlay__body">
            <nav className="offcanvas-navigation">
              <ul>
                <li className="has-children">
                  <a href="/">{headerHomePage}</a>
                </li>
                <li className="has-children">
                  <a href="#/history">{headerAboutUs}</a>
                </li>
                <li className="has-children">
                  <a href="#/history">{headerProducts}</a>
                  <ul className="sub-menu">
                    <li>
                      <a href="https://eczatrend.com/" target="_blank">
                        <span>EczaTrend</span>
                      </a>
                    </li>
                    <li>
                      <a href="http://camoballoon.com/" target="_blank">
                        <span>{headerKapadokyaBalloon}</span>
                      </a>
                    </li>
                    <li>
                      <a href="http://cafestalk.com/" target="_blank">
                        <span>Cafestalk</span>
                      </a>
                    </li>
                    <li>
                      <a href="#/history#kvkk">
                        <span>E-KVKK</span>
                      </a>
                    </li>
                    <li>
                      <a href="#/history#hbys">
                        <span>{headerHBYSSubtitle}</span>
                      </a>
                    </li>
                    <li>
                      <a href="#/history#ekobs">
                        <span>{headerEKOBSSubtitle}</span>
                      </a>
                    </li>
                    <li>
                      <a href="#/history#sayac">
                        <span>{headerSayacOkuma}</span>
                      </a>
                    </li>
                    <li>
                      <a href="#/history#token">
                        <span>Toptan TokenFlex</span>
                      </a>
                    </li>
                    <li>
                      <a href="#/history#lonca">
                        <span>Lonca Market</span>
                      </a>
                    </li>
                    {languagesData.map((item, index) => (
                      <li
                        key={index}
                        onClick={() => handleLanguageChange(item)}
                      >
                        <a>
                          <img
                            style={{ width: 30 }}
                            src={item.languageImage}
                            alt={item.languageName}
                          />
                          <span style={{ marginLeft: 5 }}>
                            {item.languageName}
                          </span>
                          {item.languageTag === languageObj.languageTag && (
                            <FaCheck
                              color={"rgb(255, 159, 64)"}
                              style={{ marginLeft: 5 }}
                            />
                          )}
                        </a>
                      </li>
                    ))}
                  </ul>
                </li>
                {languagesData.map((item, index) => (
                  <li key={index} onClick={() => handleLanguageChange(item)}>
                    <a>
                      <img
                        style={{ width: 30 }}
                        src={item.languageImage}
                        alt={item.languageName}
                      />
                      <span style={{ marginLeft: 5 }}>{item.languageName}</span>
                      {item.languageTag === languageObj.languageTag && (
                        <FaCheck
                          color={"rgb(255, 159, 64)"}
                          style={{ marginLeft: 5 }}
                        />
                      )}
                    </a>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </div>
      </div>
      {/*====================  End of mobile menu overlay  ====================*/}
      {/*====================  search overlay ====================*/}
      <div className="search-overlay" id="search-overlay">
        <div className="search-overlay__header">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-md-6 ml-auto col-4">
                {/* search content */}
                <div className="search-content text-right">
                  <span
                    className="mobile-navigation-close-icon"
                    id="search-close-trigger"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="search-overlay__inner">
          <div className="search-overlay__body">
            <div className="search-overlay__form">
              <form action="#">
                <input type="text" placeholder="Search" />
              </form>
            </div>
          </div>
        </div>
      </div>
      {/*====================  End of search overlay  ====================*/}
    </>
  );
};

export default Contact;
